/*

In this file:

// A. CDNs
// B. Placeholder Images
// C. Example Styles
// D. Active Styles

*/

//////////////////////////////////////////////
// A. CDNs
//////////////////////////////////////////////

// GitHub 

export const cdnIcons = `https://cdn.jsdelivr.net/npm/natura11y-icons@v2/dist/natura11y-icons.min.css`;
export const cdnCSS = `https://cdn.jsdelivr.net/npm/natura11y@4.0.0-alpha/dist/natura11y.min.css`;
export const cdnJS = `https://cdn.jsdelivr.net/npm/natura11y@4.0.0-alpha//dist/natura11y.min.js`;

//////////////////////////////////////////////
// B. Placeholder Images
//////////////////////////////////////////////

export const codeImgPlaceholder = `<img src="https://via.placeholder.com/800x400" alt="Placeholder" />`;
export const codeImgPlaceholderBackdrop = `<img class="opacity-50" src="https://via.placeholder.com/1500x750" alt="Placeholder" />`;

//////////////////////////////////////////////
// C. Example Styles
//////////////////////////////////////////////

export const exampleStyles = {
    // Colors
    '--primary': '#440381',
    '--secondary': '#ffcc66',
    '--dark': '#161124',
    '--light': '#f2edeb',
    '--canvas': '#ffffff',
    '--primary-text': 'white',
    '--primary-confirm': '#00cc55',
    '--primary-warn': '#ff6090',
    '--secondary-text': 'var(--dark)',
    '--secondary-confirm': '#165a2d',
    '--secondary-warn': '#a40e36',
    '--dark-confirm': '#00cc53',
    '--dark-warn': '#ff5557',
    '--a11y-link': '#0066cc',
    '--dark-link': '#21b2fa',
    // Button
    '--button-target-size': '2.5em',
    // Typography
    '--body-font-family': 'Source Sans Pro',
    '--header-font-family': 'var(--body-font-family)',
    '--button-font-family': 'var(--body-font-family)',
    '--body-line-height': '1.5',
    '--header-line-height': '1.13',
    // Layout
    '--article-sidebar-width': '200px',
    '--text-shadow-hsl': '0 0% 0%',
    // Misc
    '--modal-overlay-color': 'black',
    '--modal-overlay-opacity': '0.75',
    // CSS Properties
    fontFamily: 'var(--body-font-family)',
    lineHeight: 'var(--body-line-height)'
}

//////////////////////////////////////////////
// D. Active Styles
//////////////////////////////////////////////

export const activeLinkStyles = {
    'fontWeight': 'var(--font-weight-bold)',
    'textDecoration': 'underline'
}